import Radio from '@mui/material/Radio';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import BackofficeProposalMenu from 'components/BackofficeProposalMenu';
import { formatZipCode } from 'react-data-formatter';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import download from 'downloadjs';
import ReactLoading from 'react-loading';
import api from 'api';
import Cookies from 'js-cookie';
import { loadingColor, middleGray, primary, secondary, warn } from 'styles/colorProvider';
import { isBirthDateValid } from 'utils/birthDate';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';
import momentBD from 'utils/momentBusinessDays';
import { Header, Button, ConfirmDialog, Dialog, Input, Select, DataTable } from 'components';
import {
  ModalButton,
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  PolicyResume,
  ItemPolicy,
  Subtitle,
  Text,
  Footer,
  LoadingArea,
  Edit,
  Line,
  PhotosArea,
  PhotosItem,
  Toolbar,
  ConfirmAndCancelButtonsContainer,
  LoadingInspectionCaptures,
  InspectionCapturesList,
  BoxDialog,
} from './styles';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { formatCPF, formatPlate, formatCurrency, formatPhone } from 'react-data-formatter';
import { formatProposalStatus } from 'utils/policyStatus';
import { toast } from 'react-toastify';
import { success, lightGray, error } from 'styles/colorProvider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { dataColumns } from './helper';
import { IoCopyOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { ADJUST_VALUE, IOF } from 'utils/general';
import { ButtonsContainer } from 'screens/BackofficeArea/ProposalDetails/styles';
import { cpf } from 'cpf-cnpj-validator';
import { base64ToImage } from 'utils/base64ToImage';
import { translatePolicyModality } from 'utils/policyModality';

function ProposalDetails({ location }) {
  const proposalId = parseInt(location.pathname.split('/backoffice/detalhes-da-proposta/')[1]);

  const [proposalData, setProposalData] = useState({});
  const [reportUrl, setReportUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingGetReport, setLoadingGetReport] = useState(false);
  const [loadingCreateInspection, setLoadingCreateInspection] = useState(false);
  const [loadingCreateObservation, setLoadingCreateObservation] = useState(false);
  const [confirmCreateInspection, setConfirmCreateInspection] = useState(false);
  const [insuranceObservation, setInsuranceObservation] = useState('');
  const [confirmCreateObservation, setConfirmCreateObservation] = useState(false);

  const [loadingInspectionCaptures, setLoadingInspectionCaptures] = useState(false);
  const [loadingRequestComplementaryInspectionButton, setLoadingRequestComplementaryInspectionButton] = useState(false);
  const [showSelectInspectionCapturesModal, setShowSelectInspectionCapturesModal] = useState(false);
  const [selectedInspectionCaptures, setSelectedInspectionCaptures] = useState([]);
  const [inspectionCaptures, setInspectionCaptures] = useState([]);

  const [loadingInspectionButton, setLoadingInspectionButton] = useState(false);
  const [confirmApproveInspection, setConfirmApproveInspection] = useState(false);
  const [confirmReproveInspection, setConfirmReproveInspection] = useState(false);
  const [loadingInvoiceDownload, setLoadingInvoiceDownload] = useState(false);
  const [loadingDocumentDownload, setLoadingDocumentDownload] = useState(false);
  const [loadingUpdateValue, setLoadingUpdateValue] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [confirmNewInspection, setConfirmNewInspection] = useState(false);
  const [confirmCancelProposal, setConfirmCancelProposal] = useState(false);
  const [confirmNewPolicy, setConfirmNewPolicy] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);
  const [openDeterminedValue, setOpenDeterminedValue] = useState(false);
  const [determinedPassword, setDeterminedPassword] = useState('');
  const [openUser, setOpenUser] = useState(false);
  const [openValueProposal, setOpenValueProposal] = useState(false);
  const [openStatusProposal, setOpenStatusProposal] = useState(false);
  const [vehicleRemarked, setvehicleRemarked] = useState();
  const [userData, setUserData] = useState();
  const [updateChassi, setUpdateChassi] = useState();
  const [selectedColorVehicle, setSelectedColorVehicle] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [updateBuildYear, setUpdateBuildYear] = useState();
  const [updatePlate, setUpdatePlate] = useState();
  const [determinedYearModel, setDeterminedYearModel] = useState();
  const [determinedValue, setDeterminedValue] = useState();
  const [valueProposal, setValueProposal] = useState();
  const [openAccept, setOpenAccept] = useState(false);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const [proposalType, setProposalType] = useState('subscription');
  const [newPolicyPassword, setNewPolicyPassword] = useState('');
  const [showEditDiscount, setShowEditDiscount] = useState(true);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [discountOptions, setDiscountOptions] = useState({
    discountType: 'percent',
    discountInput: undefined,
    finalDiscountValue: undefined,
    finalDiscountPercent: undefined,
    finalValue: undefined,
  });
  const [showConfirmReject, setShowConfirmReject] = useState(false);
  const [newMotiveReject, setNewMotiveReject] = useState('');

  const [openDriver, setOpenDriver] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [loadingUpdateDriver, setLoadingUpdateDriver] = useState(false);
  const [loadingApplyDiscount, setLoadingApplyDiscount] = useState(false);
  const [newProposalStatus, setNewProposalStatus] = useState(proposalData?.status);
  const [newProposalConfirm, setNewProposalConfirm] = useState(false);
  const [proposalInfo, setProposalInfo] = useState({
    adjustmentFactor: '70%',
    adjustmentFactorValue: 70,
    userBetween18and25Years: false,
    franchiseValue: 'Normal-Obrigatória 10%',
  });

  console.log(selectedInspectionCaptures);

  const percentDiscountInformations = proposalData?.TB_LOG_PROPOSAL?.[0]?.description
    ? JSON.parse(proposalData?.TB_LOG_PROPOSAL?.[0]?.description)
    : null;

  const iof = 0.0738;

  const statusList = [
    { value: 'activated', label: 'Ativada' },
    { value: 'awaiting_vistei', label: 'Aguardando aprovação de fotos' },
    { value: 'awaiting_complementar_report', label: 'Aguardando aprovação das fotos complementares' },
    { value: 'awaiting_registration', label: 'Aguardando cadastro' },
    { value: 'awaiting_complementar', label: 'Aguardando fotos complementares' },
    { value: 'awaiting_payment', label: 'Aguardando pagamento' },
    { value: 'awaiting_inspection', label: 'Aguardando vistoria' },
    { value: 'cancelled', label: 'Cancelada' },
    { value: 'rejected', label: 'Rejeitada' },
    { value: 'expired', label: 'Expirada' },
  ];

  const loadData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/splitrisk-proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };

    const roles = await api({
      method: 'GET',
      url: `/backoffice-list-vehicle-colors`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    setLoading(true);
    const response = await api(proposalNumber);
    setRolesData(roles.data);
    setUserData(response.data.user);
    setUpdateChassi(response.data.vehicle.chassi);
    setUpdatePlate(response.data.vehicle.plate);
    setUpdateBuildYear(response.data.vehicle.manufacture_year);
    setDriverData({
      maindriver_name: response.data.maindriver_name || response.data.user.name,
      maindriver_document: response.data.maindriver_document || response.data.user?.cpf,
      maindriver_birthdate: moment.utc(response.data.maindriver_birthdate || response.data.user.birthdate).format('DD/MM/YYYY'),
    });
    let valueService = 0;
    let valueCoverage = 0;
    let hasCoverages = false;
    let hasServices = false;

    for (const item of response.data?.arrayUnique || []) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }
    if (response.data?.products) {
      for (const item of response.data?.products || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }
    if (response.data?.coverages) {
      for (const item of response.data?.coverages || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }

    if (response.data?.coverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.value_final);

      if (response?.data) {
        response.data.iof = response?.data?.value_final * IOF;
      }
    } else {
      setValueCoverage(valueCoverage);
    }

    setProposalData(response.data);

    setHasCoverages(hasCoverages);
    setHasServices(hasServices);
    setLoading(false);
    setValueService(valueService);
  };

  const handleCreateInspection = async () => {
    setLoadingCreateInspection(true);
    try {
      await api({
        method: 'POST',
        url: `/inspections/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoadingCreateInspection(false);
      setConfirmCreateInspection(false);
      toast.success('Vistoria criada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      loadData();
    } catch (e) {
      toast.error('Não foi possivel criar vistoria', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmCreateInspection(false);
      setLoadingCreateInspection(false);
    }
  };
  const handleCreateObservation = async () => {
    setLoadingCreateObservation(true);
    try {
      await api({
        method: 'PUT',
        url: `/update-proposal-observation/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          observation: insuranceObservation,
        },
        json: true,
      });
      toast.success('Observação criada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      loadData();
    } catch (e) {
      toast.error('Não foi possivel criar observação', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setInsuranceObservation('');
      setLoadingCreateObservation(false);
      setConfirmCreateObservation(false);
    }
  };

  const handleApproveInspection = async () => {
    setLoadingInspectionButton(true);
    try {
      await api({
        method: 'POST',
        url: `/proposal/${proposalId}/approve-inspection`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: { forced: proposalData?.status === 'rejected' },
      });
      setLoadingInspectionButton(false);
      setConfirmApproveInspection(false);
      toast.success('Vistoria aprovada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      loadData();
    } catch (e) {
      toast.error('Não foi possivel forçar aprovação da vistoria', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmApproveInspection(false);
      setLoadingInspectionButton(false);
    }
  };

  const handleReproveInspection = async () => {
    setLoadingInspectionButton(true);
    try {
      await api({
        method: 'POST',
        url: `/proposal/${proposalId}/reprove-inspection`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          observation: insuranceObservation,
        },
        json: true,
      });
      setLoadingInspectionButton(false);
      setConfirmReproveInspection(false);
      toast.success('Vistoria reprovada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      loadData();
    } catch (e) {
      toast.error('Não foi possivel forçar reprovação da vistoria', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmReproveInspection(false);
      setLoadingInspectionButton(false);
      setInsuranceObservation('');
    }
  };

  const handleGetInspectionCaptures = async () => {
    setShowSelectInspectionCapturesModal(true);
    if (inspectionCaptures.length > 0 || loadingInspectionCaptures) {
      return;
    }
    setLoadingInspectionCaptures(true);
    const protocol = proposalData?.inspections?.find((item) => !!item.result_date)?.inspection_code;
    try {
      const response = await api({
        method: 'GET',
        url: `/inspections/${protocol}/captures`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
      setInspectionCaptures(response.data);
    } catch (e) {
      toast.error('Não foi possivel carregar as capturas da vistoria', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setShowSelectInspectionCapturesModal(false);
    }
    setLoadingInspectionCaptures(false);
  };

  const handleRequestComplementaryInspection = async () => {
    setLoadingRequestComplementaryInspectionButton(true);
    const protocol = proposalData?.inspections?.find((item) => !!item.result_date)?.inspection_code;
    try {
      await api({
        method: 'POST',
        url: `/inspections/${protocol}/complementary`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: { capturesIds: selectedInspectionCaptures },
        json: true,
      });
      toast.success('Vistoria complementar solicitada com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setShowSelectInspectionCapturesModal(false);
      setSelectedInspectionCaptures([]);
      loadData();
    } catch (e) {
      toast.error('Não foi possivel solicitar a vistoria complementar', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingRequestComplementaryInspectionButton(false);
  };

  const handleDownloadFile = async (type) => {
    type === 'invoice' ? setLoadingInvoiceDownload(true) : setLoadingDocumentDownload(true);
    try {
      const response = await api({
        method: 'GET',
        url: `/download/vehicle/${proposalData.id_vehicle}/${type}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      const filename = response.headers['content-disposition'].split('filename=')[1];
      const image = base64ToImage(response.data, response.headers['content-type']);
      download(image, filename, response.headers['content-type']);
      type === 'invoice' ? setLoadingInvoiceDownload(false) : setLoadingDocumentDownload(false);
    } catch (e) {
      toast.error('Não foi possivel fazer o download do arquivo', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      type === 'invoice' ? setLoadingInvoiceDownload(false) : setLoadingDocumentDownload(false);
    }
  };

  const handleGetReport = async (protocol) => {
    if (reportUrl) {
      window.open(reportUrl, '_blank');
      return;
    }
    setLoadingGetReport(true);
    try {
      const response = await api({
        method: 'GET',
        url: `/inspections/${protocol}/report`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoadingGetReport(false);
      setReportUrl(response?.data.reportUrl);
      window.open(response?.data.reportUrl, '_blank');
    } catch (e) {
      toast.error('Não foi possivel acessar relatório na Infovist', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingGetReport(false);
    }
  };

  const handleUpdateDriver = async () => {
    setLoadingUpdateDriver(true);
    try {
      await api({
        method: 'patch',
        url: `/update-proposal-driver/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          maindriver_name: driverData.maindriver_name,
          maindriver_document: driverData.maindriver_document,
          maindriver_birthdate: moment(driverData.maindriver_birthdate, 'DD/MM/YYYY').format(),
        },
      });
      setLoadingUpdateDriver(false);
      toast.success('Condutor principal alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenDriver(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingUpdateDriver(false);
      setOpenDriver(false);
    }
  };

  const handleUpdateProposal = async () => {
    setLoading(true);
    try {
      await api({
        method: 'PATCH',
        url: `/backoffice-update-user/${userData.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          ...userData,
          cpf: userData.cpf.replaceAll('.', '').replace('-', ''),
          social_name: userData?.social_name?.toUpperCase(),
          phone: userData.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          birthdate: moment(userData.birthdate, 'DD/MM/YYYY'),
          gender: parseInt(userData.gender),
        },
        json: true,
      });
      setLoading(false);
      setOpenUser(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateProposalStatus = async () => {
    setLoadingUpdateStatus(true);
    try {
      await api({
        method: 'PATCH',
        url: `/update-status/${proposalData?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          newStatus: newProposalStatus,
        },
        json: true,
      });
      toast.success('Status alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingUpdateStatus(false);
      setOpenStatusProposal(false);
      loadData();
    } catch (error) {
      setLoadingUpdateStatus(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateProposalValue = async () => {
    setLoadingUpdateValue(true);
    try {
      await api({
        method: 'POST',
        url: `/fix-value-proposal-json`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          id_proposal: proposalData?.id,
          value: parseFloat(valueProposal.replace('R$ ', '').replaceAll('.', '').replace(',', '.')),
        },
        json: true,
      });
      toast.success('Valor editado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingUpdateValue(false);
      setOpenValueProposal(false);
      loadData();
    } catch (error) {
      setLoadingUpdateValue(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateVehicle = async () => {
    setLoading(true);
    try {
      await api({
        method: 'PATCH',
        url: `/edit-vehicle/${proposalData?.vehicle?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          chassi: updateChassi,
          manufacture_year: updateBuildYear,
          plate: updatePlate,
          id_color: selectedColorVehicle,
          remarked: vehicleRemarked,
        },
        json: true,
      });
      setLoading(false);
      setOpenVehicle(false);
      toast.success('Veículo atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDeterminedValueVehicle = async () => {
    setLoading(true);
    try {
      const formattedDeterminedValue = determinedValue.replace(/\D/g, '');
      await api({
        method: 'POST',
        url: `/determined-value-vehicle`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_proposal: proposalId,
          year_model: determinedYearModel,
          determined_value: formattedDeterminedValue / 100,
          determined_password: determinedPassword,
        },
        json: true,
      });
      setLoading(false);
      setDeterminedValue(false);
      toast.success('Valor determinado aplicado!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleCancelProposal = async () => {
    setLoading(true);
    try {
      await api({
        method: 'post',
        url: `/cancel-proposal/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoading(false);
      toast.success('A proposta foi cancelada!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmCancelProposal(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      setConfirmCancelProposal(false);
    }
  };

  const handleNewInspection = async () => {
    setLoadingRequest(true);
    try {
      await api({
        method: 'post',
        url: `/request-proposal-inspection/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoadingRequest(false);
      toast.success('Vistoria solicitada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmNewInspection(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingRequest(false);
      setConfirmNewInspection(false);
    }
  };

  const handleReject = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/reject-registration`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: proposalData.id,
        },
        json: true,
      });
      setLoading(false);
      setOpenAccept(false);
      toast.success('Cadastro recusado', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/accept-registration`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: proposalData.id,
          type: proposalType,
        },
        json: true,
      });
      setLoading(false);
      setOpenAccept(false);
      toast.success('Cadastro aprovado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => loadData(), []);

  const handleOpenEditVehicle = () => {
    setOpenVehicle(true);
  };

  const handleOpenDeterminedValue = () => {
    setOpenDeterminedValue(true);
  };

  const handleCloseDeterminedValue = () => {
    setOpenDeterminedValue(false);
  };

  const handleOpenEditUser = () => {
    setOpenUser(true);
  };

  const handleOpenEditValueProposal = () => {
    setOpenValueProposal(true);
  };

  const handleOpenEditStatusProposal = () => {
    setOpenStatusProposal(true);
  };

  const handleApplyDiscountProposal = async (discountInput) => {
    setLoadingApplyDiscount(true);
    try {
      await api({
        method: 'POST',
        url: `/apply-discount/${proposalData?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          discount_percent: discountInput,
        },
        json: true,
      });

      toast.success('Desconto aplicado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingApplyDiscount(false);
      loadData();
    } catch (error) {
      setLoadingApplyDiscount(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const toggleSelection = (item) => {
    setSelectedInspectionCaptures((prevSelectedItems) =>
      prevSelectedItems.includes(item) ? prevSelectedItems.filter((i) => i !== item) : [...prevSelectedItems, item],
    );
  };

  const toggleSelectAllInspectionCaptures = (event) => {
    if (event.target.checked) {
      setSelectedInspectionCaptures(inspectionCaptures.map((item) => item.id));
    } else {
      setSelectedInspectionCaptures([]);
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={confirmNewInspection}
      onCancel={() => setConfirmNewInspection(false)}
      content="Tem certeza de que deseja solicitar uma nova vistoria? A vistoria atual será inativada"
      title="Solicitar nova vistoria"
      onConfirm={handleNewInspection}
      loading={loadingRequest}
    />
  );

  const renderConfirmCancel = () => (
    <ConfirmDialog
      open={confirmCancelProposal}
      onCancel={() => setConfirmCancelProposal(false)}
      content="Tem certeza de que deseja cancelar a proposta?"
      title="Cancelar proposta"
      onConfirm={handleCancelProposal}
      loading={loadingRequest}
    />
  );

  const renderConfirmNewPolicy = () => (
    <ConfirmDialog
      open={confirmNewPolicy}
      onCancel={() => setConfirmNewPolicy(false)}
      content={
        <Input
          widht="30%"
          label="Digite a senha"
          // value={""}
          onChange={(event) => setNewPolicyPassword(event.target.value)}
          height="30px"
          type="password"
          password
        />
      }
      title="Solicitar emissão da apólice"
      onConfirm={async () => {
        setLoading(true);
        setConfirmNewPolicy(false);
        try {
          const resp = await api({
            method: 'POST',
            url: `/policy-manual`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitrisk-authtoken'),
            },
            params: {
              secret: newPolicyPassword,
            },
            data: {
              id_proposal: proposalData.id,
            },
            json: true,
          });
          toast.success('Apólice criada com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } catch (error) {
          console.log('error', error);
          toast.error('Atenção! A apólice não foi criada! ', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
        setLoading(false);
        loadData();
      }}
    ></ConfirmDialog>
  );

  const renderConfirmReject = () => (
    <ConfirmDialog
      open={showConfirmReject}
      onCancel={() => setShowConfirmReject(false)}
      content={
        <TextareaAutosize
          onChange={(e) => setNewMotiveReject(e.target.value)}
          value={newMotiveReject}
          placeholder="Insira o motivo"
          style={{
            padding: 10,
            fontFamily: 'Nunito',
            fontSize: 14,
            marginTop: '3%',
            width: '95%',
            height: '160px',
            borderRadius: 5,
            outline: 'none',
            borderColor: middleGray,
          }}
        />
      }
      title="Reprovar cadastro da proposta"
      onConfirm={async () => {
        setLoading(true);
        setShowConfirmReject(false);
        try {
          const resp = await api({
            method: 'POST',
            url: `/reject-registration`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitrisk-authtoken'),
            },
            data: {
              id: proposalData.id,
              reject_motive: newMotiveReject,
            },
            json: true,
          });
          toast.success('Cadastro rejeitado', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } catch (error) {
          console.log('error', error);
          toast.error('Erro ao rejeitar cadastro! ', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
        setLoading(false);
        loadData();
      }}
    ></ConfirmDialog>
  );

  const handleDiscount = (e) => {
    const discountValue = parseFloat(e.target.value.replace('R$ ', '').replace(',', '.'));

    if (discountOptions?.discountType === 'value') {
      const fDiscountValue = discountValue;
      const fDiscountPercent = (discountValue * 100) / proposalData?.final_value;
      const fValue = proposalData?.final_value - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
    }

    if (discountOptions?.discountType === 'percent') {
      const fDiscountValue = (discountValue / 100) * proposalData?.value_final;
      const fDiscountPercent = discountValue;
      const fValue = proposalData?.value_final - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
    }
  };
  const actionDataColumns = [
    ...dataColumns,
    {
      name: 'Link',
      selector: (row) => (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(row?.inspection_url);
            toast.success('Link para vistoria copiado com sucesso!', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
          }}
        >
          <IoCopyOutline size={15} />
        </IconButton>
      ),
      width: '10%',
      sortable: true,
    },
  ];

  if (proposalData?.inspections?.some((insp) => !!insp.result_date)) {
    actionDataColumns.push({
      name: 'Relatorio',
      selector: (row) => (
        <>
          {row?.result_date && (
            <Button height="25px" width="200px" loading={loadingGetReport} onClick={() => handleGetReport(row.inspection_code)}>
              &nbsp; Visualizar documento &nbsp; <IoDocumentTextOutline />
            </Button>
          )}
        </>
      ),
      width: '20%',
      sortable: false,
    });
  }

  const renderUpdateStatusProposal = () => (
    <Dialog open={openStatusProposal} onClose={() => setOpenStatusProposal(false)} width="40%" title="Editar status da proposta">
      {proposalData.status === 'activated' && (
        <Alert severity="warning">Propostas ativadas não podem ter o status alterado!</Alert>
      )}
      <Select label="Novo status" value={newProposalStatus} onChange={(e) => setNewProposalStatus(e.target.value)}>
        <option>Selecione o novo status</option>
        {statusList
          ?.filter((i) => i.value !== proposalData.status)
          ?.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
      </Select>

      <label style={{ fontSize: 12, marginTop: 20, marginBottom: 20, color: '#c0392b', maxWidth: 320 }} htmlFor="checkbox">
        <input
          name="confirmStatus"
          type="checkbox"
          value={newProposalConfirm}
          onChange={(e) => setNewProposalConfirm(!newProposalConfirm)}
        />
        Entendo que esta ação apenas alterará o status da proposta, e que ao executá-la, nenhuma ação adicional, como solicitação
        de vistoria ou emissão de apólice, será realizada.
      </label>
    </Dialog>
  );

  const renderConfirmCreateInspection = () => (
    <Dialog
      title="Tem certeza que deseja criar vistoria para essa proposta?"
      open={confirmCreateInspection}
      onClose={() => setConfirmCreateInspection(false)}
    >
      {!!proposalData?.vehicle?.zero_km && proposalData?.inspections?.length === 0 && (
        <Alert severity="warning">Essa Proposta não possui vistoria pois foi sinalizada como Veículo 0KM</Alert>
      )}
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmCreateInspection(false)}>
          cancelar
        </Button>
        <Button className="confirm" onClick={handleCreateInspection} loading={loadingCreateInspection}>
          confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderConfirmCreateObservation = () => (
    <Dialog
      title="Tem certeza que deseja criar uma observação para essa vistoria?"
      open={confirmCreateObservation}
      onClose={() => {
        setConfirmCreateObservation(false);
        setInsuranceObservation('');
      }}
    >
      <BoxDialog>
        <Input
          value={insuranceObservation}
          onChange={(e) => setInsuranceObservation(e.target.value)}
          label="Observação"
          height="30px"
          width="100%"
        />
      </BoxDialog>
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmCreateObservation(false)}>
          Cancelar
        </Button>
        <Button
          className="confirm"
          onClick={handleCreateObservation}
          loading={loadingCreateObservation}
          disabled={!insuranceObservation?.trim()}
        >
          Confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderConfirmApproveInspection = () => (
    <Dialog
      title="Tem certeza que deseja forçar a aprovação de vistoria para essa proposta?"
      open={confirmApproveInspection}
      onClose={() => setConfirmApproveInspection(false)}
    >
      <Alert severity="warning">Ao confirmar a proposta será atualizada para etapa de "Aguardando cadastro"</Alert>
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmApproveInspection(false)}>
          cancelar
        </Button>
        <Button className="confirm" onClick={handleApproveInspection} loading={loadingInspectionButton}>
          confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderConfirmReproveInspection = () => (
    <Dialog
      title="Tem certeza que deseja forçar a reprovação de vistoria para essa proposta?"
      open={confirmReproveInspection}
      onClose={() => {
        setConfirmReproveInspection(false);
        setInsuranceObservation('');
      }}
    >
      <Alert severity="warning">Ao confirmar a proposta será atualizada para "Rejeitada"</Alert>
      <BoxDialog>
        <Input
          value={insuranceObservation}
          onChange={(e) => setInsuranceObservation(e.target.value)}
          width="100%"
          label="Observação"
          height="30px"
        />
      </BoxDialog>
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmReproveInspection(false)}>
          cancelar
        </Button>
        <Button
          className="confirm"
          onClick={handleReproveInspection}
          loading={loadingInspectionButton}
          disabled={!insuranceObservation?.trim()}
        >
          confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderSelectInspectionCaptures = () => (
    <Dialog
      title="Selecione as imagens que devem ser incluídas na vistoria complementar"
      open={showSelectInspectionCapturesModal}
      onClose={() => setShowSelectInspectionCapturesModal(false)}
    >
      {loadingInspectionCaptures ? (
        <LoadingInspectionCaptures>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          <p>Buscando imagens da vistoria...</p>
        </LoadingInspectionCaptures>
      ) : (
        <>
          <InspectionCapturesList>
            {inspectionCaptures.map((item) => (
              <div
                key={item.id}
                onClick={() => toggleSelection(item.id)}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '5px 0' }}
              >
                <div style={{ marginRight: '10px' }}>
                  {selectedInspectionCaptures.includes(item.id) ? (
                    <RadioButtonCheckedIcon style={{ color: secondary }} />
                  ) : (
                    <RadioButtonUncheckedIcon style={{ color: primary }} />
                  )}
                </div>
                <span>{item.step}</span>
              </div>
            ))}
          </InspectionCapturesList>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedInspectionCaptures.length === inspectionCaptures.length}
                onChange={toggleSelectAllInspectionCaptures}
                style={{ color: primary }}
              />
            }
            style={{ marginLeft: 0, gap: 10 }}
            label="Selecionar todos"
          />
          <ConfirmAndCancelButtonsContainer marginTop="10px" secondary={secondary} lightGray={lightGray}>
            <Button className="cancel" onClick={() => setShowSelectInspectionCapturesModal(false)}>
              Cancelar
            </Button>
            <Button
              className="confirm"
              onClick={handleRequestComplementaryInspection}
              loading={loadingRequestComplementaryInspectionButton}
              disabled={selectedInspectionCaptures.length === 0}
            >
              Confirmar
            </Button>
          </ConfirmAndCancelButtonsContainer>
        </>
      )}
    </Dialog>
  );

  useEffect(() => {
    // LMI LOGIC
    const auctionOrRemarked = proposalData?.vehicle?.auction || proposalData?.vehicle?.remarked;
    // Starts with 70% <- Auction or Remarked
    let adjustmentFactor = '70%';
    let adjustmentFactorValue = 70;

    if (!auctionOrRemarked) {
      const adjustmentsFactors = proposalData.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_FIPE_LMI_TB_QUOTATION_PROPOSAL?.filter(
        (item) => item?.active,
      );

      // If has valid LMI or 100%
      adjustmentFactor = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.name || '100%';
      adjustmentFactorValue = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.lmi || 100;
    }

    // Franchise Logic
    const showFranchiseValue =
      proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE?.enabled;

    const franchiseValue = proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.filter(
      (c) => c?.active,
    )?.[0]?.TB_PRICING_DEDUCTIBLE?.name;
    // Apólices antigas
    const oldFranchiseValue = proposalData.coverages?.find((c) => c?.name?.includes('Franquia'))?.name_complete;

    // User Between 18 and 25 years
    const userBetween18and25Years = proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old;

    setProposalInfo({
      ...proposalInfo,
      adjustmentFactor,
      adjustmentFactorValue,
      userBetween18and25Years,
      franchiseValue:
        showFranchiseValue && franchiseValue ? franchiseValue : oldFranchiseValue ? oldFranchiseValue : 'Normal-Obrigatória 10%',
    });
  }, [proposalData]);

  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      {renderUpdateStatusProposal()}
      {renderConfirmDialog()}
      {renderConfirmReject()}
      {renderConfirmNewPolicy(false)}
      {renderConfirmCancel(false)}
      {renderConfirmCreateInspection()}
      {renderConfirmCreateObservation()}
      {renderConfirmApproveInspection()}
      {renderConfirmReproveInspection()}
      {renderSelectInspectionCaptures()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <BackofficeProposalMenu proposalId={proposalId} proposalData={proposalData} />
            <Title>DETALHES DA PROPOSTA</Title>

            <PolicyResume>
              <p>Protocolo: {proposalData?.protocol}</p>
              <p>
                Status da proposta:{' '}
                <span style={{ color: formatProposalStatus(proposalData?.status)?.color }}>
                  {formatProposalStatus(proposalData?.status)?.label}
                </span>
              </p>
              <p>Modalidade de contratação: {translatePolicyModality(proposalData?.modality)}</p>
              <p>Valor: {formatCurrency(proposalData?.valueFinal || 0)}</p>
              <p>
                Fator de ajuste LMI: <span>{proposalInfo?.adjustmentFactor}</span>
              </p>
              <p>
                Franquia: <span>{proposalInfo?.franchiseValue}</span>
              </p>

              <Toolbar></Toolbar>
              {proposalData?.status !== 'awaiting_payment' && (
                <FormControl style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <RadioGroup
                    value={proposalType}
                    onChange={(e) => {
                      setProposalType(e.target.value);
                    }}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="subscription"
                      sx={{ color: '#2a034f' }}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: '#07bc0c',
                            },
                          }}
                        />
                      }
                      label="Subscrição(padrão)"
                    />
                  </RadioGroup>
                </FormControl>
              )}

              {proposalData?.status === 'rejected' && <p>Motivo de rejeição da proposta: {proposalData?.reject_motive}</p>}
              {proposalData?.insurance_observation && <p>Observação: {proposalData?.insurance_observation}</p>}
            </PolicyResume>

            {proposalData?.status === 'awaiting_activation' && (
              <div>
                <div className="buttonCreatePolicy">
                  <Button
                    height="20px"
                    style={{ marginLeft: 0, maxWidth: '120px', marginTop: '40px' }}
                    onClick={() => setConfirmNewPolicy(true)}
                  >
                    Emitir apólice
                  </Button>
                </div>
              </div>
            )}

            {proposalData?.status === 'rejected' && (
              <ButtonsContainer>
                <Button height="30px" width="250px" onClick={() => setConfirmApproveInspection(true)}>
                  Forçar aprovação de vistoria
                </Button>
                <Button className="complementary-inspection" height="30px" width="250px" onClick={handleGetInspectionCaptures}>
                  Solicitar vistoria complementar
                </Button>
                <Button
                  className="complementary-inspection"
                  height="30px"
                  width="250px"
                  onClick={() => setConfirmCreateObservation(true)}
                >
                  Adicionar Observação
                </Button>
              </ButtonsContainer>
            )}

            {proposalData?.status === 'awaiting_vistei' && (
              <ButtonsContainer>
                <Button height="30px" width="250px" onClick={() => setConfirmApproveInspection(true)}>
                  Aprovar vistoria
                </Button>
                <Button height="30px" className="reprove" width="250px" onClick={() => setConfirmReproveInspection(true)}>
                  Reprovar vistoria
                </Button>
                <Button height="30px" className="complementary-inspection" width="250px" onClick={handleGetInspectionCaptures}>
                  Solicitar vistoria complementar
                </Button>
              </ButtonsContainer>
            )}

            <PolicyInformationArea>
              <Title fontSize="22px" color={success}>
                <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                  <BsFillCheckCircleFill size={20} color={success} />
                </IconButton>
                Etapa 1 - Vistoria
              </Title>
              <Line />
              <ItemPolicy style={{ width: '94%' }}>
                <DataTable
                  responsive
                  columns={actionDataColumns}
                  data={proposalData?.inspections}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="100%"
                  progressPending={loading}
                  progressComponent={
                    <LoadingArea>
                      <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                    </LoadingArea>
                  }
                  noDataComponent={
                    <Button height="30px" loading={loadingCreateInspection} onClick={() => setConfirmCreateInspection(true)}>
                      Criar vistoria
                    </Button>
                  }
                />
              </ItemPolicy>
              {proposalData?.status === 'awaiting_inspection' && (
                <Title fontSize="22px" color={lightGray}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={lightGray} />
                  </IconButton>
                  Etapa 2 - Cadastro
                </Title>
              )}
              {proposalData?.status !== 'awaiting_inspection' && (
                <Title fontSize="22px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 2 - Cadastro
                </Title>
              )}
              <Line />
              <div className="tempo">
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do segurado</Subtitle>
                    {proposalData?.status !== 'awaiting_payment' && proposalData?.status !== 'activated' && (
                      <Button height="25px" width="55px" onClick={handleOpenEditUser}>
                        Editar
                      </Button>
                    )}
                  </Edit>
                  <Text>
                    Nome do segurado: <span>{proposalData?.user?.name}</span>
                  </Text>
                  <Text>
                    Nome social: <span>{proposalData?.user?.social_name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(proposalData?.user?.cpf)}</span>
                  </Text>
                  {isCpf && (
                    <Text>
                      Data de nascimento: <span>{moment.utc(proposalData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                    </Text>
                  )}
                  <Text>
                    Telefone: <span>{formatPhone(proposalData?.user?.phone)}</span>
                  </Text>
                  <Text>
                    E-mail: <span>{proposalData?.user?.email}</span>
                  </Text>

                  <Dialog open={openUser} onClose={() => setOpenUser(false)} width="50%" title="Editar segurado">
                    <Input
                      widht="30%"
                      label="Nome"
                      value={userData?.name}
                      onChange={(e) => setUserData({ ...userData, name: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      label="Nome social"
                      value={userData?.social_name}
                      onChange={(e) => setUserData({ ...userData, social_name: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      label="CPF"
                      mask={'999.999.999-99'}
                      value={userData?.cpf}
                      onChange={(e) => setUserData({ ...userData, cpf: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      type="email"
                      label="E-mail"
                      value={userData?.email}
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      height="30px"
                    />
                    <Input
                      height="35px"
                      mask="99/99/9999"
                      label="Data de nascimento"
                      placeholder="Data de nascimento"
                      value={moment(userData?.birthdate).format('DD/MM/YYYY')}
                      onChange={(e) => setUserData({ ...userData, birthdate: e.target.value })}
                    />
                    <Input
                      widht="30%"
                      label="Telefone"
                      mask="(99) 9 9999-9999"
                      value={userData?.phone}
                      onChange={(e) => setUserData({ ...userData, phone: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <ModalButton>
                      <Button
                        height="35px"
                        onClick={handleUpdateProposal}
                        disabled={
                          !userData?.name || !userData?.cpf || !userData?.email || !userData?.phone || !userData?.birthdate
                        }
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>
                  {/*
                  <Dialog
                    open={openValueProposal}
                    onClose={() => setOpenValueProposal(false)}
                    width="40%"
                    title="Editar valor da proposta"
                  >
                    <Input
                      widht="30%"
                      label="Valor"
                      numeric
                      price
                      value={valueProposal}
                      onChange={(e) => setValueProposal(e.target.value)}
                      height="30px"
                    />
                    <ModalButton>
                      <Button
                        loading={loadingUpdateValue}
                        height="35px"
                        onClick={handleUpdateProposalValue}
                        disabled={!valueProposal || !proposalData?.value_final}
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog> */}
                </ItemPolicy>

                {/* CONDUTOR PRINCIPAL */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do condutor principal</Subtitle>
                    <Button height="20px" width="70px" style={{ fontSize: 12 }} onClick={() => setOpenDriver(true)}>
                      Editar
                    </Button>
                  </Edit>

                  <Text>
                    Nome: <span>{driverData?.maindriver_name || driverData?.user?.name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(driverData?.maindriver_document || driverData?.user?.cpf)}</span>
                  </Text>
                  {isCpf && (
                    <Text>
                      Data de nascimento: <span>{driverData?.maindriver_birthdate || driverData?.user?.birthdate}</span>
                    </Text>
                  )}
                  <Dialog open={openDriver} onClose={() => setOpenDriver(false)} width="50%" title="Editar condutor">
                    <Input
                      height="30px"
                      label="Nome"
                      value={driverData?.maindriver_name}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_name: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="CPF"
                      mask="999.999.999-99"
                      value={driverData?.maindriver_document}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_document: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="Data de nascimento"
                      mask="99/99/9999"
                      value={driverData?.maindriver_birthdate}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_birthdate: e.target.value })}
                    />
                    <ModalButton>
                      <Button
                        loading={loadingUpdateDriver}
                        height="35px"
                        onClick={handleUpdateDriver}
                        style={{ margin: 0, marginTop: 20 }}
                        disabled={
                          loadingUpdateDriver ||
                          !driverData?.maindriver_name ||
                          !driverData?.maindriver_document ||
                          !driverData?.maindriver_birthdate
                        }
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do veículo</Subtitle>
                    {proposalData?.status === 'awaiting_registration' && (
                      <Button height="25px" width="55px" onClick={handleOpenEditVehicle}>
                        Editar
                      </Button>
                    )}
                    <Button height="20px" width="120px" onClick={handleOpenDeterminedValue}>
                      <h6>Valor determinado</h6>
                    </Button>
                  </Edit>
                  <Text>
                    Marca e modelo: <span>{`${proposalData?.vehicle?.brand} ${proposalData?.vehicle?.model}`}</span>
                  </Text>
                  <Text>
                    Ano/modelo: <span>{`${proposalData?.vehicle?.manufacture_year}/${proposalData?.vehicle?.year_model}`}</span>
                  </Text>
                  <Text>
                    Placa: <span>{formatPlate(proposalData?.vehicle?.plate)}</span>
                  </Text>
                  <Text>
                    Chassi: <span>{proposalData?.vehicle?.chassi}</span>
                  </Text>
                  <Text>
                    Uso do veículo: <span>{`${proposalData?.vehicle?.vehicle_use?.name}`}</span>
                  </Text>
                  <Text>
                    Cor do veículo: <span>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</span>
                  </Text>
                  <Text>
                    Veículo de leilão: <span>{proposalData?.vehicle?.auction ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    Chassis Remarcado/Veiculo Recuperado de Sinistro:{' '}
                    <span>{proposalData?.vehicle?.remarked ? 'Sim' : 'Não'}</span>
                  </Text>
                  <Text>
                    GNV: <span>{proposalData?.vehicle?.gnv ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    Condutor entre 18 e 25 anos:{' '}
                    <span>{proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    Valor FIPE: <span>{formatCurrency(proposalData?.vehicle?.value || 0)}</span>
                  </Text>
                  <Text>
                    LMI {proposalInfo?.adjustmentFactor}:{' '}
                    <span>{formatCurrency(proposalData?.vehicle?.value * (proposalInfo?.adjustmentFactorValue / 100) || 0)}</span>
                  </Text>

                  <ButtonsContainer>
                    {proposalData?.vehicleHasInvoice && (
                      <Button height="20px" loading={loadingInvoiceDownload} onClick={() => handleDownloadFile('invoice')}>
                        Baixar nota fiscal
                      </Button>
                    )}
                    {proposalData?.vehicleHasDocument && (
                      <Button height="20px" loading={loadingDocumentDownload} onClick={() => handleDownloadFile('document')}>
                        Baixar documento
                      </Button>
                    )}
                  </ButtonsContainer>

                  <Dialog open={openVehicle} onClose={() => setOpenVehicle(false)} width="50%" title="Editar veículo">
                    <Input
                      height="30px"
                      label="Placa"
                      mask="***-9*99"
                      value={updatePlate}
                      onChange={(e) => setUpdatePlate(e.target.value)}
                    />
                    <Input
                      height="30px"
                      label="Chassi"
                      mask="*****************"
                      value={updateChassi}
                      onChange={(e) => setUpdateChassi(e.target.value.toUpperCase())}
                    />
                    <Input
                      height="30px"
                      label="Ano de fabricação"
                      mask="9999"
                      value={updateBuildYear}
                      onChange={(e) => setUpdateBuildYear(e.target.value)}
                    />
                    <Select label="Cor do veículo" height="30px" onChange={(e) => setSelectedColorVehicle(e.target.value)}>
                      <option value="">Selecione</option>
                      {rolesData?.map((role) => (
                        <option value={role.id}>{role.name}</option>
                      ))}
                    </Select>
                    <Select
                      label=" Chassis Remarcado/Veiculo Recuperado de Sinistro:"
                      height="30px"
                      onChange={(e) => (e.target.value === 'true' ? setvehicleRemarked(true) : setvehicleRemarked(false))}
                    >
                      <option value="">Selecione</option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Select>
                    <ModalButton style={{ marginTop: 20 }}>
                      <Button height="35px" style={{ margin: 0 }} onClick={handleUpdateVehicle} disabled={!updateChassi}>
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>

                  <Dialog
                    open={openDeterminedValue}
                    onClose={() => setDeterminedValue(false)}
                    width="50%"
                    title="Valor determinado"
                  >
                    <Input
                      height="30px"
                      label="Ano Modelo"
                      value={determinedYearModel}
                      onChange={(e) => setDeterminedYearModel(e.target.value)}
                    />
                    <Input
                      height="30px"
                      label="Valor determinado"
                      value={determinedValue}
                      onChange={(e) => setDeterminedValue(e.target.value.toUpperCase())}
                      numeric
                      price
                    />
                    <Input
                      label="Senha"
                      placeholder="Digite a senha para determinar o valor"
                      value={determinedPassword}
                      onChange={(e) => setDeterminedPassword(e.target.value)}
                      password
                      type="password"
                    />
                    <ModalButton style={{ marginTop: 20 }}>
                      <Button height="35px" style={{ margin: 0 }} onClick={handleDeterminedValueVehicle} disabled={!updateChassi}>
                        Confirmar
                      </Button>
                      <Button height="35px" style={{ margin: 0.5 }} onClick={handleCloseDeterminedValue} disabled={!updateChassi}>
                        Fechar
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                {hasCoverages && (
                  <ItemPolicy>
                    <Subtitle>Coberturas</Subtitle>
                    {proposalData?.products?.length &&
                      proposalData?.products
                        .filter((coverage) => coverage.isService === false)
                        .map((coverage) => <Text>• {coverage.name} </Text>)}
                    {proposalData?.coverages?.length &&
                      proposalData?.coverages
                        .filter((coverage) => coverage.isService === false)
                        .map((product) => <Text>• {product.name} </Text>)}
                    {proposalData?.arrayUnique?.length &&
                      proposalData?.arrayUnique
                        .filter((coverage) => coverage.isService === false)
                        .map((array) => <Text>• {array.name} </Text>)}
                  </ItemPolicy>
                )}

                <ItemPolicy>
                  <Subtitle style={{ width: '100%', justifyContent: 'space-between' }}>Informações financeiras </Subtitle>

                  {hasCoverages && (
                    <Text>
                      Valor Coberturas:{' '}
                      <span>
                        {formatCurrency((proposalData?.isHinova ? proposalData?.valueFinal : valueCoverage) - proposalData?.iof)}
                      </span>
                    </Text>
                  )}
                  {hasCoverages && (
                    <Text>
                      Valor IOF sobre Coberturas: <span>{`${formatCurrency(proposalData?.iof || 0)}`}</span>
                    </Text>
                  )}
                  {hasServices && (
                    <Text>
                      Valor Serviços: <span>{`${formatCurrency(valueService)}`}</span>
                    </Text>
                  )}
                  <Text>
                    Valor Total do Seguros: <span>{formatCurrency(proposalData?.valueFinal || 0)}</span>
                  </Text>
                  <h4> Informações sobre desconto:</h4>

                  {proposalData.discount_new > 0 && (
                    <Text>
                      Valor do desconto: <span>{formatCurrency(proposalData?.discount_new)}</span>
                    </Text>
                  )}

                  {proposalData.discount_new > 0 && (
                    <Text>
                      Percentual do desconto: <span>{`${percentDiscountInformations?.discount_percentage}%`}</span>
                    </Text>
                  )}

                  {proposalData.discount_new <= 0 && (
                    <Text>
                      Percentual do desconto <span>{`${proposalData?.TB_QUOTATION_PROPOSAL?.discount_percent || 0}%`}</span>
                    </Text>
                  )}
                  {percentDiscountInformations && (
                    <Text>
                      Valor final sem desconto: <span>{formatCurrency(percentDiscountInformations?.old_value || 0)}</span>
                    </Text>
                  )}
                  <Text>
                    Valor final com desconto: <span>{formatCurrency(proposalData?.valueFinal || 0)}</span>
                  </Text>

                  <div>
                    <h4> Informações sobre agravo de valor:</h4>
                    <Text>
                      Valor do agravo:{' '}
                      <span>{`${proposalData?.premium_seller && formatCurrency(proposalData?.premium_seller || 0)}`}</span>
                    </Text>
                    <Text>
                      Percentual de agravo: <span>{`${proposalData?.TB_QUOTATION_PROPOSAL?.grievance_percent || 0}%`}</span>
                    </Text>
                    <Text>
                      Valor final com agravo: <span>{formatCurrency(proposalData?.valueFinal || 0)}</span>
                    </Text>
                  </div>

                  {!proposalData.isHinova &&
                    showEditDiscount &&
                    (proposalData.discount_new === 0 || proposalData.discount_new === null) && (
                      <section className="discount-area" style={{ marginTop: 30 }}>
                        <h4 style={{ margin: 0, marginBottom: 15 }}>Editar desconto</h4>
                        <Select
                          value={discountOptions?.discountType}
                          onChange={(e) => setDiscountOptions({ ...discountOptions, discountType: e.target.value })}
                          label="Tipo de desconto"
                          height="30px"
                          style={{ margin: 0 }}
                        >
                          <option value="percent">Porcentagem</option>
                        </Select>
                        <Input
                          style={{ margin: 0 }}
                          height="30px"
                          label={discountOptions?.discountType === 'percent' ? 'Percentual de desconto' : 'Valor de desconto'}
                          numeric
                          price={discountOptions?.discountType === 'value'}
                          value={discountOptions?.discountInput}
                          onChange={handleDiscount}
                        />

                        <>
                          <p style={{ margin: 0, fontSize: 12, marginTop: 10 }}>
                            Percentual de desconto:{' '}
                            {discountOptions?.finalDiscountPercent ? discountOptions?.finalDiscountPercent : 0}%{' '}
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>
                            Valor final com desconto: {formatCurrency(discountOptions?.finalValue || 0)}
                          </p>
                          <Button
                            loading={loadingApplyDiscount}
                            disabled={loadingApplyDiscount}
                            onClick={() => handleApplyDiscountProposal(discountOptions?.finalDiscountPercent)}
                            height="30px"
                          >
                            Salvar alterações
                          </Button>
                        </>
                      </section>
                    )}
                </ItemPolicy>
                {hasServices && (
                  <ItemPolicy>
                    <Edit>
                      <Subtitle>Serviços</Subtitle>
                    </Edit>
                    {proposalData?.products?.length &&
                      proposalData?.products
                        .filter((coverage) => coverage.isService === true)
                        .map((coverage) => <Text>• {coverage.name} </Text>)}
                    {proposalData?.coverages?.length &&
                      proposalData?.coverages
                        .filter((product) => product.isService === true)
                        .map((product) => <Text>• {product.name} </Text>)}
                    {proposalData?.arrayUnique?.length &&
                      proposalData?.arrayUnique
                        .filter((array) => array.isService === true)
                        .map((array) => <Text>• {array.name} </Text>)}
                  </ItemPolicy>
                )}
                {/* ENDEREÇO */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Endereço do segurado</Subtitle>
                  </Edit>
                  <Text>
                    CEP: <span>{formatZipCode(proposalData?.address?.zip_code)}</span>
                  </Text>
                  <Text>
                    Endereço: <span>{proposalData?.address?.street}</span>
                  </Text>
                  <Text>
                    Número:{' '}
                    <span>
                      {proposalData?.address?.number} {proposalData?.address?.complement}
                    </span>
                  </Text>
                  <Text>
                    Bairro: <span>{proposalData?.address?.district}</span>
                  </Text>
                  <Text>
                    Cidade/UF:{' '}
                    <span>
                      {proposalData?.address?.city}/{proposalData?.address?.state}
                    </span>
                  </Text>
                  <Text>
                    Complemento: <span>{proposalData?.address?.complement}</span>
                  </Text>
                </ItemPolicy>
              </div>
              {proposalData?.status !== 'awaiting_payment' && proposalData?.status !== 'cancelled' && (
                <Title fontSize="22px" color={lightGray}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={lightGray} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}
              {proposalData?.status === 'awaiting_payment' && (
                <Title fontSize="22px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}
              {proposalData?.status === 'cancelled' && (
                <Title fontSize="22px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}

              <Line />
              <ItemPolicy>
                <Subtitle>Primeiro pagamento</Subtitle>
                <Text>
                  Status da proposta: <span>{formatProposalStatus(proposalData?.status)?.label}</span>
                </Text>
                <Text>
                  Vencimento: <span>{momentBD(proposalData?.accepted_at).businessAdd(3, 'day').format('DD/MM/YYYY')}</span>
                </Text>
                <Text>
                  Mensalidade: <span>{formatCurrency(proposalData?.value_final || 0)}</span>
                </Text>
              </ItemPolicy>
              {proposalData?.status === 'awaiting_registration' && (
                <Footer>
                  <div className="area-button">
                    <Button
                      onClick={handleGetInspectionCaptures}
                      style={{
                        width: '250px',
                        borderRadius: '10px',
                        height: '40px',
                        fontSize: '12px',
                        backgroundColor: `${warn}`,
                        color: 'black',
                      }}
                    >
                      Solicitar vistoria complementar
                    </Button>
                    <Button
                      onClick={() => setShowConfirmReject(true)}
                      style={{
                        width: '160px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${error}`,
                        color: 'black',
                      }}
                    >
                      Reprovar cadastro
                    </Button>
                    <Button
                      onClick={() => handleAccept()}
                      style={{
                        width: '150px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: 'black',
                      }}
                    >
                      Aprovar cadastro
                    </Button>
                  </div>
                </Footer>
              )}
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ProposalDetails;
